<template>
  <div class="monitor contentWrapper">
    <h1>
      <vue-typed-js
        class="fullWidth"
        @onComplete="displayR()"
        :typeSpeed="50"
        :startDelay="500"
        :backSpeed="50"
        :backDelay="2000"
        :loop="false"
        :strings="[
          'HEAD TRACKING FOR GAMING',
          'HEAD TRACKING FOR FLYING',
          'HEAD TRACKING FOR RACING',
          'HEAD TRACKING FOR FPS',
          'PUT YOUR HEAD IN THE GAME!',
        ]"
      >
        <span id="typingWrapper" class="typing"></span>
      </vue-typed-js>
    </h1>
    <div class="monitor-container">
      <router-link class="cta" to="/trackir5/">
        <img
          class="static-fallback"
          :src="imagesUrl + 'homepage-demo-static@2x.png'"
        />
        <img
          class="display-surround"
          :src="imagesUrl + 'trackir-5-and-monitor-cutout@2x.png'"
        />
        <video
          id="video-monitor"
          class="demo-video"
          autoplay
          muted
          loop
          preload="auto"
          :poster="imagesUrl + 'video-game-poster.jpg'"
        >

          <source
            :src="videosUrl + 'game-view-pip-optimized.mp4'"
            type="video/mp4"
          />

        </video>
        
        <div  v-if="glitchMessage" class="monitorOverlay">
          <div class="glitch" :data-text="glitchMessage">{{ glitchMessage }}</div>
          <div class="glow">{{ glitchMessage }}</div>
          <div class="scanlines"></div>
        </div>
        

      </router-link>
    </div>
    <!-- END monitor-container -->
    <div class="content">
      <p>
        Featuring a wide field of view, high frame rate, and 6DoF tracking
        technology, the TrackIR 5 dramatically increases situational awareness,
        adding depth to the PC gaming experience and pulling you further into
        the game.
      </p>
      <router-link to="/trackir5/" class="btn">TrackIR 5 In Depth</router-link>
      <router-link to="/downloads/" class="btn"
        >Download the Software</router-link
      >
    </div>
    <!-- END content -->
  </div>
  <!-- END monitor -->
</template>

<script>
import { VueTypedJs } from "vue-typed-js";
import { IMAGES_URL, VIDEOS_URL } from "@/constants/";
export default {
  name: "Monitor",
  props: {
    glitchMessage: { type: String, default: null },
  },
  computed: {
    imagesUrl: function () {
      return IMAGES_URL;
    },
    videosUrl: function () {
      return VIDEOS_URL;
    },
  },
  components: {
    VueTypedJs,
  },
  methods: {
    displayR: function () {
      /* Adds the registered mark to the end of the typing */
      var node = document.createElement("span");
      node.className = "regMark";
      node.innerHTML = "&reg;";
      document.getElementById("typingWrapper").appendChild(node);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.monitor {
  padding: 2px 0 65px;
}

.fullWidth {
  display: block;
  width: 100%;
  text-align: center;
}
h1 {
  font-size: 45px;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 40px;
}
.content {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  a {
    width: 260px;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-bottom: 10px;

    @include for-medium-up {
      width: 280px;
      padding: 10px 52px;
      margin-bottom: 0px;
    }
  }

  p {
    margin-bottom: 36px;
  }
}
p {
  text-align: center;
  margin-bottom: 40px;
}

.monitor-container {
  height: 200px;
  position: relative;
  overflow: hidden;

  @include for-medium-up {
    height: 280px;
  }


  .static-fallback {
    display: none;
  }

  .display-surround {
    width: 300px;
    height: 200px;
    position: absolute;
    left: -5px;
    top: 0px;
  }

  video {
    width: 272px;
    position: absolute;
    top: 22px;
    left: 9px;
  }

  @include for-medium-up {
    height: 530px;
    .display-surround {
      width: 821px;
      height: 549px;
      left: -60px;
      top: 0px;
    }
    video {
      width: 742px;
      top: 61px;
      left: -20px;
    }
  }
}


.monitorOverlay {
    position: absolute;
    top: 22px;
    left: 9px;
    width: 272px;
    height: 113px;
    background-color: rgba(0,0,0,0.8);
    overflow: hidden;
    padding:0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

     @include for-medium-up {
      top: 61px;
      left: 0px;
      width: 702px;
      height: 308px;
     }
}

.glitch {
  color: rgb(223, 191, 191);
  font-size: 36px;
  font-weight: bold;
  animation: glitch 5s 5s infinite;
  text-align: center;

  @include for-medium-up {
    font-size: 84px;
  }
}

.glitch::before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: -5px 0 magenta;
  background: rgba(0,0,0,0.2);
  overflow: hidden;
  top: 0;
  bottom: 0;
  animation: noise-1 3s linear infinite alternate-reverse, glitch 5s 5.05s infinite;
}

.glitch::after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -5px 0 lightgreen;
  background: rgba(0,0,0,0.2);
  overflow: hidden;
  top: 0;
  bottom: 0;
  animation: noise-2 3s linear infinite alternate-reverse, glitch 5s 5s infinite;
}

.scanlines {
  overflow: hidden;
  mix-blend-mode: difference;
}

.scanlines::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: repeating-linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.05) .5%,
    transparent 1%
  );

  animation: fudge 7s ease-in-out alternate infinite;
}
.glow {
  @extend .glitch;
  text-shadow: 0 0 1000px rgb(223, 191, 191);
  color: transparent;
  position: absolute;
  top: 0;
}


@keyframes fudge {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0px, 2%);
  }
}
@keyframes glitch {
  1%{
    transform: rotateX(10deg) skewX(90deg);
  }
  2%{
    transform: rotateX(0deg) skewX(0deg);
  }
}

@keyframes noise-1 {
  $steps: 16;
  @for $i from 1 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(308);
      $bottom: random(309 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

@keyframes noise-2 {
  $steps: 16;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      $top: random(308);
      $bottom: random(309 - $top);
      clip-path: inset(#{$top}px 0 #{$bottom}px 0);
    }
  }
}

::v-deep .regMark {
  vertical-align: text-top;
  font-size: 14px;
}
</style>
